import React from "react"
import Row from "reactstrap/es/Row"
import Col from "reactstrap/es/Col"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import FeaturesHtmlSerializer from "../utils/FeaturesHtmlSerializer"

const HomeFeatureLeft = ({ featureDetails }) => {
  return (
    <Row className="align-items-center">
      <Col lg="6" md="6">
        <GatsbyImage
          image={featureDetails.imageSrc}
          alt={featureDetails.imageAlt}
          className="img-fluid rounded"/>
      </Col>
      <Col lg="6" md="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div className="section-title ml-lg-5">
          <h2 className="title mb-4">{featureDetails.title}</h2>
          <RichText render={featureDetails.content} htmlSerializer={FeaturesHtmlSerializer}/>
        </div>
      </Col>
    </Row>
  )
}

HomeFeatureLeft.propTypes = {
  featureDetails: PropTypes.object
}

export default HomeFeatureLeft
