import React from "react"
import { graphql } from "gatsby"
import { Col, Container, Row } from "reactstrap"

import Layout from "../components/layout"
// Import images
import user from "../assets/images/user.svg"
import calendar from "../assets/images/calendar.svg"
import sandClock from "../assets/images/sand-clock.svg"
import CTA from "../components/cta"
import SEO from "../components/SEO/seo"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import HomeFeatureRight from "../components/homeFeatureRight"
import HomeFeatureLeft from "../components/homeFeatureLeft"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"
import SignupForm from "../components/signupForm"

const Index = ({ data: { prismicHomepage } }) => {

  const features = []
  prismicHomepage.data.body.map(function(featureItem, key) {
    features.push({
      imageSrc: featureItem.primary.image.localFile.childImageSharp.gatsbyImageData,
      imageAlt: featureItem.primary.image.alt,
      title: featureItem.primary.name,
      content: featureItem.primary.content.raw
    })
  })

  const featureArray = [
    {
      id: 1,
      title: "Simple",
      description:
        "Une interface colorée et intuitive pour une prise en main immédiate, et quelqu'un est toujours disponible pour vous aider.",
      imgUrl: user
    },
    {
      id: 2,
      title: "Efficace",
      description:
        "Vous êtes averti à chaque échéance importante afin de toujours rester en règle avec l'administration fiscale.",
      imgUrl: calendar
    },
    {
      id: 3,
      title: "Rapide",
      description:
        "Une interface simplifiée à l'extrême pour que vous puissiez envoyer une facture en moins de 30 secondes.",
      imgUrl: sandClock
    }
  ]
  const reviews = [
    {
      id: 1,
      name: "Artee'st-shop",
      desc:
        "Bizyness est tout simplement génial pour un micro entrepreneur ! L'interface est très bien faite et simple d'utilisation. Je recommande ! je suis entièrement satisfait.",
      rating: 5
    },
    {
      id: 2,
      name: "Jonathan",
      desc:
        "RAS. Outil très utile, évolutif et Français. J'ai testé d'autres alternative sans être vraiment convaincu... Et derrière y'a un SAV dispo et à l'écoute des remarques. Je ne peux que conseillé cette app !",
      rating: 5
    },
    {
      id: 3,
      name: "Elodie",
      desc:
        "Je suis très satisfaite de ce site, je trouve cela très pratique et je gagne beaucoup de temps. Le fondateur est toujours à l'écoute et réactif quand il faut apporter des modifications.",
      rating: 5
    },
    {
      id: 4,
      name: "Myriam",
      desc:
        "Très satisfaite de Bizyness. Logiciel facile d'utilisation. Pleinement adapté aux micro-entrepreneurs. Service client très réactif. Toujours prêt à répondre aux questions.",
      rating: 5
    },
    {
      id: 5,
      name: "Pascal",
      desc:
        "Bizyness est super facile pour effectuer sa compta.nous sommes auto entrepreneur dans la mecanique. aussi nous avons un interlocuteur tres présent pour toute demande. je suis très contente et j ai renouvelé mon abonnement depuis 3 ans. Merci Bizyness",
      rating: 5
    },
    {
      id: 6,
      name: "Audrey",
      desc:
        "Une application crée pour simplifier les démarches administratives et être en règle en France. Merci car du coup on peut se consacrer à d'autres choses. Service client très réactif et à l'écoute des clients pour l'amélioration du logiciel. Je recommande",
      rating: 5
    }
  ]

  return (
    <Layout>
      <SEO
        title={prismicHomepage.data.seotitle}
        desc={prismicHomepage.data.seodescription}
      />
      <section className="bg-home d-flex align-items-center" id="home">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="text-center mt-0 mt-md-5 pt-0 pt-md-5">
              <div className="title-heading margin-top-100">
                <h1 className="heading mb-3">
                  {prismicHomepage.data.heading}
                </h1>
                <h2 className="para-desc mx-auto text-muted subheading">
                  {prismicHomepage.data.subheading}
                </h2>
                <Row className="mt-4 justify-content-center">
                  <Col md="9" lg="7" xl="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <SignupForm id={"home-top"} signin={false}/>
                  </Col>
                </Row>
                <div className="mt-4 pt-2">

                </div>
              </div>

              <div className="home-dashboard">
                <GatsbyImage
                  image={prismicHomepage.data.image.localFile.childImageSharp.gatsbyImageData}
                  alt={prismicHomepage.data.image.alt}
                  className="img-fluid"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section bg-light mt-0 mt-md-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="10" className="text-center">
              <div className="section-title mb-4 pb-2">
                <h2 className="title mb-4 big-font">
                  {prismicHomepage.data.title}
                </h2>
                <p className="text-muted mx-auto mb-0 big-text">
                  {prismicHomepage.data.subtitle}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-4">
          <Row>
            {featureArray.map((feat, i) => {
              return (
                <Col md={4} xs={12} key={i} name="featurebox">
                  <div className={"features text-center"}>
                    <div className="image position-relative d-inline-block">
                      <img
                        src={feat.imgUrl}
                        className="avatar avatar-small"
                        alt="Declaration URSSAF en auto entreprise"
                      />
                    </div>
                    <div className="content mt-4">
                      <h3 className="title-2">{feat.title}</h3>
                      <p className="text-muted mb-0">{feat.description}</p>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        {features.map((featureItem, key) =>
          <Container>
            {key % 2 === 0 ? <HomeFeatureLeft featureDetails={featureItem}/> :
              <HomeFeatureRight featureDetails={featureItem}/>}
          </Container>
        )}
        <CTA id={"home-bottom"}/>
      </section>
    </Layout>
  )
}

export default withPrismicPreview(Index, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver
  }
])

Index.propTypes = {
  data: PropTypes.shape({
    prismicHomepage: PropTypes.object.isRequired
  }).isRequired
}

export const pageQuery = graphql`
  query Index {
    prismicHomepage {
      _previewable
      data {
        body {
          ... on PrismicHomepageDataBodyFeature {
            id
            primary {
              content {
                raw
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
              name
            }
          }
        }
        heading
        subheading
        subtitle
        title
        seotitle
        seodescription
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 90, placeholder: NONE, layout: CONSTRAINED)
            }
          }
          alt
        }
      }
    }
  }
`
