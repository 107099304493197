import React from "react"
import Container from "reactstrap/es/Container"
import Row from "reactstrap/es/Row"
import Col from "reactstrap/es/Col"
import { getTrialDays } from "../assets/javascript/affiliate"
import SignupForm from "../components/signupForm"
import PropTypes from "prop-types"

const CTA = ({ id, affiliateId, tagsId, couponsId, planId, campaignId }) => {
  return (
    <Container className="container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60" id={"signupcta"}>
      <Row className="justify-content-center">
        <Col xs="12" className="text-center">
          <div className="section-title">
            <h2 className="title mb-4">
              {getTrialDays()} jours gratuits pour essayer toutes les fonctionnalités
            </h2>
            <p className="text-muted para-desc mx-auto mb-0">
              Commencez avec{" "}
              <span className="text-primary font-weight-bold">Bizyness</span>{" "}
              gratuitement, sans engagement ni carte de crédit.
            </p>

            <Row className="mt-4 justify-content-center">
              <Col md="9" lg="7" xl="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <SignupForm id={id} affiliateId={affiliateId} tagsId={tagsId} couponsId={couponsId} planId={planId}
                            campaignId={campaignId}/>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

CTA.propTypes = {
  id: PropTypes.string,
  affiliateId: PropTypes.string,
  tagsId: PropTypes.string,
  couponsId: PropTypes.string,
  planId: PropTypes.string,
  campaignId: PropTypes.string
}

export default CTA
